import {
  Badge,
  Box,
  Button,
  Container,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import Image from 'next/image'
import NextLink from 'next/link'
import { MemberSpotlightArticle } from './MemberSpotlightArticle/MemberSpotlightArticle.types'

export const HomePageHero = (props: {
  article: MemberSpotlightArticle | null
}) => {
  return (
    <>
      <Box backgroundColor='red.500'>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          textAlign='center'
          p={4}
          justify='center'
          gap={4}
          align='center'>
          <Text color='white' as='div'>
            Win a Grandfalls Retractable Pressure Washer PRO+ Grandstorm
            Retractable Vacuum Cleaner
          </Text>
          <div>
            <Button as='a' href='/contest'>
              Enter Giveaway
            </Button>
          </div>
        </Stack>
      </Box>
      <Box
        backgroundImage='url(/img/nationHeroBackground.webp)'
        backgroundSize='cover'
        p={{ base: 4, lg: 12 }}
        pb={{ base: 0, lg: 0 }}
        backgroundColor='blue.900'
        color='white'>
        <Stack
          mt='auto'
          mb='auto'
          textAlign='center'
          py={{ base: 12, lg: 0 }}
          px={{ base: 0, lg: 8 }}>
          <Box>
            <Heading
              as='h1'
              fontSize={{ base: 'lg', lg: 'xl' }}
              textTransform='uppercase'
              mb={3}
              mx='auto'>
              From American Muscle to Classics to&nbsp;Diesels
            </Heading>
            <Heading
              as='h2'
              fontSize={{ base: '5xl', lg: '8xl' }}
              lineHeight={1}
              textTransform='uppercase'>
              Project cars from
              <br />
              around the&nbsp;world
            </Heading>
            <Heading
              as='h3'
              mt={4}
              fontSize={{ base: 'xl', lg: '4xl' }}
              lineHeight={1}
              textTransform='uppercase'>
              Share your builds, be inspired
            </Heading>
          </Box>
        </Stack>
        {props.article && <Spotlight article={props.article} />}
      </Box>
    </>
  )
}

type SpotlightProps = {
  article: MemberSpotlightArticle
}

const Spotlight = (props: SpotlightProps) => {
  return (
    <Box
      mx='auto'
      maxW='4xl'
      pb={{ base: 8, lg: 12 }}
      style={{
        backgroundImage:
          'linear-gradient(rgba(78,103,147,0), rgba(78,103,147,1))',
      }}>
      <Container maxW='3xl' mx='auto'>
        <Stack align='start'>
          {props.article?.hero_image?.url ? (
            <Image
              alt={props.article.hero_image.alt ?? ''}
              height={props.article.hero_image.height}
              src={props.article.hero_image.url}
              width={props.article.hero_image.width}
              style={{
                marginInline: 'auto',
                width: '100%',
                maxWidth: '900px',
                marginTop: '80px',
              }}
            />
          ) : (
            <></>
          )}
          <Stack gap={3} style={{ marginTop: '-36px' }}>
            <Stack align='start'>
              <Badge backgroundColor='cyan.400' color='white'>
                Spotlight
              </Badge>
              <Heading size='4xl'>{props.article.title}</Heading>
            </Stack>
            <Box maxW='xl'>
              {props.article.highlighted_quote ? (
                <Box pos='relative'>
                  <Box
                    pos='absolute'
                    fontSize='4xl'
                    fontFamily='sans-serif'
                    top='-10px'
                    left='-15px'>
                    “
                  </Box>
                  <Text as='span' fontSize='lg'>
                    {props.article.highlighted_quote}
                  </Text>
                  <Box display='inline' fontFamily='sans-serif'>
                    ”
                  </Box>
                </Box>
              ) : (
                <Box pos='relative'>{props.article.intro}</Box>
              )}
            </Box>
            <Stack direction='row' align='center' gap={4}>
              <Button
                as='a'
                href={`/spotlight/${props.article.slug}`}
                colorScheme='red'>
                See Build Story
              </Button>
              <NextLink href='/spotlight' passHref legacyBehavior>
                <Link color='white'>Browse Spotlight Articles</Link>
              </NextLink>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}
